<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="data-list-list-view" class="data-list-container">
    <vs-table
      ref="table"
      pagination
      :max-items="itemsPerPage"
      search
      :data="reps"
    >
      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow">
        <!-- ITEMS PER PAGE -->
        <vs-dropdown
          vs-trigger-click
          class="cursor-pointer mb-4 mr-4 items-per-page-handler"
        >
          <div
            class="
              p-4
              border border-solid
              d-theme-border-grey-light
              rounded-full
              d-theme-dark-bg
              cursor-pointer
              flex
              items-center
              justify-between
              font-medium
            "
          >
            <span class="mr-2"
              >{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} -
              {{
                rep.length - currentPage * itemsPerPage > 0
                  ? currentPage * itemsPerPage
                  : rep.length
              }}
              of {{ queriedItems }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
          <vs-dropdown-menu>
            <vs-dropdown-item @click="itemsPerPage = 4">
              <span>4</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 15">
              <span>15</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 20">
              <span>20</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>

        <div @click="addRep = true">
          <vs-button
            class="
              btn-add-new
              p-3
              mb-4
              mr-4
              rounded-lg
              cursor-pointer
              flex
              items-center
              justify-center
            "
          >
            <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
            <span class="ml-2">اضافة مندوب</span>
          </vs-button>
        </div>
      </div>

      <template slot="thead">
        <vs-th sort-key="title">الإسم</vs-th>
        <vs-th sort-key="phone">رقم الهاتف</vs-th>
        <vs-th sort-key="actions">العمليات</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <vs-tr :data="rep" v-for="(rep, index) in data" :key="index">
            <!-- in all these place holders replace them with {{contest.ObjectHere}} -->

            <vs-td>
              <p class="product-name font-medium truncate">
                {{ rep.name }}
              </p>
            </vs-td>

            <vs-td>
              <p class="product-category">{{ rep.phone }}</p>
            </vs-td>
            <vs-td>
              <feather-icon
                icon="EditIcon"
                svgClasses="w-5 h-5 hover:text-primary stroke-current"
                @click="(editRepBool = true), editRepBtn(rep.id)"
              />
              <feather-icon
                icon="PrinterIcon"
                svgClasses="w-5 h-5 hover:text-success stroke-current"
                class="ml-2"
                @click="pushRoute(rep.id)"
              />
              <feather-icon
                icon="ArchiveIcon"
                svgClasses="w-5 h-5 hover:text-warning stroke-current"
                class="ml-2"
                @click.stop="confirmDelete(rep.id)"
              />
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>
    <div class="demo-alignment">
      <vs-popup class="holamundo" title="إضافة مندوب" :active.sync="addRep">
        <div class="flex flex-col justify-items-center">
          <div class="vx-col w-1/2 w-full mb-2">
            <vs-input
              size="large"
              type="text"
              class="w-full"
              v-validate="'required|alpha_spaces|min:4'"
              label="إسم المندوب"
              v-model="rep.name"
              data-vv-as="إسم المندوب"
              name="rep_name"
            />
          </div>
          <span class="text-danger text-sm" v-show="errors.has('rep_name')">{{
            errors.first("rep_name")
          }}</span>
          <div class="vx-col w-1/2 w-full mb-2 mt-10 flex align-center">
            <vs-input
              type="text"
              size="large"
              class="w-full"
              v-validate="'required|digits:10'"
              label="رقم الهاتف"
              placeholder="09X XXX XX XX"
              aria-placeholder="test"
              v-model="rep.phone"
              data-vv-as="رقم الهاتف"
              name="rep_phone"
            />
          </div>
          <span class="text-danger text-sm" v-show="errors.has('rep_phone')">{{
            errors.first("rep_phone")
          }}</span>

          <div class="mb-6">&#8203;</div>
          <vs-button
            @click="createRep()"
            color="primary"
            type="filled"
            icon-pack="feather"
            icon="icon-plus"
          >
            إضــافة مندوب</vs-button
          >
        </div>
      </vs-popup>
    </div>

    <div class="edit-rep">
      <vs-popup class="edit-rep" title="تعديل مندوب" :active.sync="editRepBool">
        <div class="flex flex-col justify-items-center">
          <div class="vx-col w-1/2 w-full mb-2">
            <vs-input
              size="large"
              type="text"
              class="w-full"
              label-placeholder="إسم المندوب"
              v-validate="'required|alpha_spaces|min:4'"
              v-model="rep.name"
              data-vv-as="إسم المندوب"
              name="rep_edit"
            />
          </div>
          <span class="text-danger text-sm" v-show="errors.has('rep_edit')">{{
            errors.first("rep_edit")
          }}</span>
          <div class="vx-col w-1/2 w-full mb-2 mt-10 flex align-center">
            <vs-input
              type="text"
              size="large"
              class="w-full"
              label="رقم الهاتف"
              v-validate="'required|digits:10'"
              aria-placeholder="test"
              placeholder="09X XXX XX XX"
              v-model="rep.phone"
              data-vv-as="رقم الهاتف"
              name="edit_phone"
            />
          </div>
          <span class="text-danger text-sm" v-show="errors.has('edit_phone')">{{
            errors.first("edit_phone")
          }}</span>

          <div class="mb-6">&#8203;</div>
          <vs-button
            @click="editRep()"
            color="primary"
            type="filled"
            icon-pack="feather"
            icon="icon-edit"
          >
            حفظ التعديلات</vs-button
          >
        </div>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex'

export default {
  data() {
    return {
      selected: [],
      isMounted: false,
      current_Page: 1,
      itemsPerPage: 5,
      lastPage: 0,
      totalItems: 0,
      addRep: false,
      editRepBool: false,
      rep_delete: "",
      rep: {
        id: "",
        name: "",
        phone: "",
      },
    };
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },

    queriedItems() {
      return this.$refs.table
        ? this.$refs.table.queriedResults.length
        : this.rep.length;
    },
     reps(){
      return this.$store.state.reps.reps
    }
  },
  methods: {
     ...mapActions('reps', [
      'fetchReps',
      'archiveRep',
      'createNewRep',
      'updateRep'
    ]),
    pushRoute(id){
      this.$router.push({ name: 'RepReport', params: { id } });
    },
    async createRep() {
      let result = await this.$validator.validateAll().then((result) => {
        return result;
      });

      if (result == false) {
        this.$vs.notify({
          title: "حدث خطأ",
          text: "يرجى ملئ كافة الحقول",
          color: "danger",
        });
        return false;
      }

      this.$vs.loading();
      var bodyFormData = new FormData();
      bodyFormData.set("name", this.rep.name);
      bodyFormData.set("phone", this.rep.phone);
      this.addRep = false;
      this.createNewRep(bodyFormData)
        .then((response) => {
          this.reps.unshift(response);
          this.$vs.loading.close();
          this.$vs.notify({
            title: "تمت العملية",
            text: "تمت العملية بنجاح.",
            color: "success",
          });

          // this.successDialog(response);
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.errorDialog(error);
        });
    },
    editRepBtn(id) {
      //slapping data into the pop up
      var holder = this.reps.find((x) => x.id === id);
      this.rep.id = holder.id;
      this.rep.name = holder.name;
      this.rep.phone = holder.phone;
    },
    editRep() {
      this.$vs.loading();
      var bodyFormData = new FormData();
      bodyFormData.set("id", this.rep.id);
      bodyFormData.set("name", this.rep.name);
      bodyFormData.set("phone", this.rep.phone);
      let id = this.rep.id;
      this.editRepBool = false;
      this.updateRep({ id, bodyFormData })
        .then((response) => {
          // this.reps.unshift(response);
          this.$vs.loading.close();
          this.$vs.notify({
            title: "تمت العملية",
            text: "تمت العملية بنجاح.",
            color: "success",
          });
          var holder = this.reps.find((x) => x.id === id);
          holder.name = this.rep.name;
          holder.phone = this.rep.phone;
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "حدث خطأ",
            text: "العملية غير ناجحة.",
            color: "danger",
          });
        });
    },
    confirmDelete(id) {
      this.rep_delete = id;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: "تأكيد العملية",
        text: "هل انت متأكد من أرشفة هذا المندوب؟",
        acceptText: "تأكيد",
        cancelText: "الغاء",
        accept: this.deleteData,
      });
    },

    deleteData() {
      this.archiveRep(this.rep_delete)
        .then(() => {
          this.$vs.notify({
            title: "تم بنجاح",
            text: "تم أرشفة المندوب بنجاح",
            color: "success",
          });
        })
        .catch((error) => {
         this.errorDialog(error);
        });
    },
  },
  created() {
  this.fetchReps()
  },
  mounted() {
    this.isMounted = true;
  },
  watch: {
    addRep: function () {
      // to clear edit data when add rep is triggered
      this.rep = { id: "", name: "", phone: "" };
    },
  },
};
</script>
